<template>
  <div id="create_target_modal">
    <a-modal
      :title="lbl['smart-target-group-condition']"
      :visible="visible"
      :footer="null"
      :maskClosable="false"
      width="70%"
      centered
      @cancel="handleCancel"
      class="create-target-modal"
    >
      <div class="wrapper">
        <div class="progress-container">
          <div
            v-for="step in stepList"
            :key="step.number"
            class="step-item"
            @click="clickStep(step.number)"
          >
            <div v-if="step.number" class="detail">
              <div
                class="number"
                :class="{ 'active-number': step.number === currentStep }"
              >
                {{ step.number }}
              </div>
              <div
                class="title"
                :class="{ 'active-title': step.number === currentStep }"
              >
                {{ step.title }}
              </div>
            </div>
            <div
              v-if="step.number"
              class="line"
              :class="{ 'active-line': step.number === currentStep }"
            ></div>
            <div v-if="!step.number" class="icon-next">{{ step.title }}</div>
          </div>
        </div>
        <div class="name-contianer">
          <div class="title">
            {{ lbl['smart-target-seatch-name'] + ':' }}
          </div>
          <a-input
            v-model="targetName"
            :placeholder="lbl['smart-target-seatch-name-placeholder']"
            size="large"
            class="input-name"
            :class="{ error: error }"
            @change="onInputName"
          />
          <div class="reset" @click="reset()">
            {{ lbl['user-permissions-reset'] }}
          </div>
        </div>
        <!-- 1  Progress-->
        <div v-if="currentStep == 1" class="first-progress">
          <div class="criteria-contianer">
            <!-- Head Column -->
            <div class="filter-column">
              {{ lbl['smart-target-filter-column'] }}
            </div>
            <div class="criteria-column">
              {{ lbl['smart-target-criteria-column'] }}
            </div>
            <div class="summary-column">
              {{ lbl['smart-target-summary-column'] }}
            </div>
            <!-- End Head Column -->
            <!-- Select Section -->
            <div class="filter-select-container">
              <div
                v-for="item in filterList"
                :key="item.name"
                class="item-container"
                :class="{ select: item.name == currentFillter }"
                @click="handleSelectFilter(item.name)"
              >
                <div class="name">
                  <span>{{ item.name }}</span>
                  <div class="noti" v-if="notEmptySelected(item.name)"></div>
                </div>
                <img
                  :src="require('@/assets/images/navigate_next.png')"
                  alt="next"
                />
              </div>
            </div>
            <div class="criteria-select-container">
              <div v-if="criteriaList.type == 'rangedate'" class="date-select">
                <div class="title">{{ 'Select Date' }}</div>
                <a-range-picker
                  v-model="selectedCriteria[indexCurrent].selected"
                  format="DD/MMM/YYYY"
                  :ranges="ranges"
                  @change="onChangeDateRange"
                />
              </div>
              <div v-if="criteriaList.type == 'checkbox'" class="w-100">
                <a-checkbox-group
                  :v-model="selectedCriteria[indexCurrent].selected"
                  class="check-group"
                  @change="onChangeCheckbox"
                >
                  <div
                    v-for="option in criteriaList.options"
                    :key="option.value"
                    class="check-container"
                  >
                    <a-checkbox :value="option.value">
                      {{ option.name }}
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
              </div>
            </div>
            <div class="summary-select-container">
              <div v-if="selectedCriteria.length">
                <div
                  v-for="item in selectedCriteria"
                  :key="item.name"
                  class="group-container"
                >
                  <div v-if="item.selected.length" class="group-name">
                    <span>{{ item.name + ':' }}</span>
                  </div>
                  <div v-if="item.type == 'checkbox'">
                    <div
                      v-for="element in item.selected"
                      :key="element"
                      class="element"
                    >
                      <span>
                        {{ element }}
                      </span>
                      <img
                        :src="require('@/assets/images/close.png')"
                        alt="delete"
                        @click="deleteCriteria(item.name, element, item.type)"
                      />
                    </div>
                  </div>
                  <div v-if="item.type == 'rangedate' && item.selected.length">
                    <div class="element">
                      <span>
                        {{ displayRangeDate(item.selected) }}
                      </span>
                      <img
                        :src="require('@/assets/images/close.png')"
                        alt="delete"
                        @click="
                          deleteCriteria(item.name, item.selected, item.type)
                        "
                      />
                    </div>
                  </div>
                  <div v-if="item.selected.length" class="line"></div>
                </div>
              </div>
            </div>
            <!-- End Select Section -->
          </div>
        </div>
        <!-- End 1  Progress-->
        <!-- 2  Progress-->
        <div v-if="currentStep == 2" class="second-progress">
          <div class="summary-container">
            <div class="condition-list"></div>
            <div class="summary"></div>
          </div>
        </div>
        <!-- End 2 Progress-->

        <!-- Footer-->
        <div class="footer-container">
          <a-button
            v-if="currentStep == 1"
            class="ghost-btn"
            @click="handleCancel"
          >
            <strong>
              {{ lbl['btn_cancel'] }}
            </strong>
          </a-button>
          <a-button
            v-if="currentStep == 2"
            class="ghost-btn"
            @click="currentStep = 1"
          >
            <img
              src="@/assets/images/userlevel/ArrowLeft_yellow.svg"
              class="mr-1 iconlang"
              alt="arrowBack"
            />
            <strong>
              {{ lbl['btn_back'] }}
            </strong>
          </a-button>
          <a-button
            v-if="currentStep == 1"
            type="primary"
            :disabled="isDisableNext"
            @click="handleClickNext()"
          >
            <strong>
              {{ lbl['btn_next'] }}
            </strong>
            <img
              src="@/assets/images/userlevel/arrow_forward.svg"
              class="ml-1 iconlang"
              alt="arrow_forward"
            />
          </a-button>
          <a-button
            v-if="currentStep == 2"
            type="primary"
            @click="handleClickCreate()"
          >
            <strong>
              {{ lbl['smart-target-create-group-by-this-criteria'] }}
            </strong>
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Mixin from '@/mixin/Mixin'
import moment from 'moment'
import Utility from '@/helper/Utility'

export default {
  name: 'AddTemplateModal',
  mixins: [Mixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ranges: Utility.ranges,
      visible: false,
      currentStep: 1,
      targetName: null,
      stepList: [
        {
          number: 1,
          title: 'Select Criteria & Condition',
        },
        {
          title: '>',
        },
        {
          number: 2,
          title: 'Preview & Confirm',
        },
      ],
      filterList: [],
      criteriaList: [],
      currentFillter: null,
      selectedCriteria: [],
      indexCurrent: 0,
      error: false,
    }
  },
  watch: {
    show(val) {
      this.visible = val
    },
  },
  computed: {
    isDisableNext() {
      return this.selectedCriteria.every(condion => {
        return !(condion.selected && condion.selected.length > 0)
      })
    },
  },
  created() {
    this.getFilterList()
    this.stepList = [
      {
        number: 1,
        title: this.lbl['smart-target-group-condition'],
      },
      {
        title: '>',
      },
      {
        number: 2,
        title: this.lbl['smart-target-select-criteria-title'],
      },
    ]
  },
  methods: {
    getFilterList() {
      return new Promise(resolve => {
        this.handleLoading(true)
        this.filterList = [
          {
            name: 'Gender',
            type: 'checkbox',
            options: [
              {
                name: 'Female',
                value: 'Female',
              },
              {
                name: 'Male',
                value: 'Male',
              },
              {
                name: 'Others',
                value: 'Others',
              },
            ],
          },
          {
            name: 'Age',
            type: 'checkbox',
            options: [
              {
                name: '< 15 Years',
                value: 15,
              },
              {
                name: '23 - 30 Years',
                value: 23,
              },
              {
                name: '31 - 45 Years',
                value: 31,
              },
              {
                name: '46 - 60 Years',
                value: 46,
              },
              {
                name: '> 60 Years',
                value: 60,
              },
            ],
          },
          {
            name: 'Birth Month',
            type: 'checkbox',
            options: [
              {
                name: 'January',
                value: 'January',
              },
              {
                name: 'February',
                value: 'February',
              },
              {
                name: 'March',
                value: 'March',
              },
              {
                name: 'April',
                value: 'April',
              },
              {
                name: 'May',
                value: 'May',
              },
              {
                name: 'June',
                value: 'June',
              },
              {
                name: 'July',
                value: 'July',
              },
              {
                name: 'August',
                value: 'August',
              },
              {
                name: 'September',
                value: 'September',
              },
              {
                name: 'October',
                value: 'October',
              },
              {
                name: 'November',
                value: 'November',
              },
              {
                name: 'December',
                value: 'December',
              },
            ],
          },
          {
            name: 'Register Date',
            type: 'rangedate',
          },
          {
            name: 'User Level',
            type: 'checkbox',
            options: [
              {
                name: 'Silver',
                value: 'Silver',
              },
              {
                name: 'Gold',
                value: 'Gold',
              },
              {
                name: 'Platinuum',
                value: 'Platinum',
              },
            ],
          },
          {
            name: 'Province',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Last Active',
            type: 'rangedate',
            options: [],
          },
          {
            name: 'Last Redeemed',
            type: 'rangedate',
            options: [],
          },
          {
            name: 'Last Point Earned Date',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Point Expired',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Spending (THB)',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Spending (Points)',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Earn Points',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Point On Hand',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Earn At Branch',
            type: 'select',
            options: [],
          },
          {
            name: 'Redeem (Points)',
            type: 'checkbox',
            options: [],
          },
          {
            name: 'Redeem (Branch)',
            type: 'select',
            options: [],
          },
        ]
        resolve(this.filterList)
      })
    },
    handleSelectFilter(name) {
      console.log('handleSelectFilter', name)
      this.currentFillter = name

      const filter_selected = this.filterList.find(item => item.name === name)
      var data = {
        name: filter_selected.name,
        selected: [],
      }
      if (
        !this.selectedCriteria.find(item => item.name == this.currentFillter)
      ) {
        this.selectedCriteria.push(data)
      }
      this.indexCurrent = this.selectedCriteria.findIndex(
        item => item.name === this.currentFillter,
      )
      this.criteriaList = filter_selected
      console.log(this.currentFillter)
      console.log(this.criteriaList)
      console.log(this.selectedCriteria)
    },
    onChangeCheckbox(e) {
      console.log('onChangeCheckbox', e)
      this.selectedCriteria.find(
        item => item.name === this.criteriaList.name,
      ).selected = e
      this.selectedCriteria.find(
        item => item.name === this.criteriaList.name,
      ).type = 'checkbox'
    },
    onChangeDateRange(e) {
      console.log('onChangeDateRange', e)

      this.selectedCriteria.find(
        item => item.name === this.criteriaList.name,
      ).selected = e
      this.selectedCriteria.find(
        item => item.name === this.criteriaList.name,
      ).display = `${e[0].format('DD/MMM/YYYY')} - ${e[1].format(
        'DD/MMM/YYYY',
      )}`
      this.selectedCriteria.find(
        item => item.name === this.criteriaList.name,
      ).type = 'rangedate'
    },
    deleteCriteria(group, element, type) {
      console.log('deleteCriteria', group, element, type)

      var indexCurrent = this.selectedCriteria.findIndex(
        item => item.name === group,
      )
      var indexElement = this.selectedCriteria[indexCurrent].selected.findIndex(
        item => item === element,
      )

      if (type == 'rangedate') {
        this.selectedCriteria[indexCurrent].selected = []
      } else {
        if (indexElement !== -1) {
          this.selectedCriteria[indexCurrent].selected.splice(indexElement, 1)
        }
      }
    },
    clickStep(e) {
      this.currentStep = e
    },
    handleClickNext() {
      this.currentStep = 2
    },
    handleClickCreate() {
      console.log('handleClickCreate')
      if (this.targetName) {
        console.log('pass')
        this.error = false
      } else {
        this.error = true
      }
    },
    handleCancel() {
      this.visible = false
      this.reset()
      this.$emit('close')
    },
    onInputName(e) {
      console.log(e.target.value)
      if (!e.target.value) this.error = true
      else this.error = false
    },
    reset() {
      this.error = false
      this.targetName = null
      this.criteriaList = []
      this.currentFillter = null
      this.selectedCriteria = []
      this.indexCurrent = 0
    },
    displayRangeDate(dateArray) {
      try {
        return `${moment(dateArray[0]).format('DD/MMM/YYYY')} - ${moment(
          dateArray[1],
        ).format('DD/MMM/YYYY')}`
      } catch {
        return dateArray
      }
    },
    notEmptySelected(group) {
      if (this.selectedCriteria.length) {
        if (this.selectedCriteria.find(item => item.name === group)) {
          if (
            this.selectedCriteria.find(item => item.name === group).selected
          ) {
            return (
              this.selectedCriteria.find(item => item.name === group).selected
                .length > 0
            )
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #4a4a4a;
  .progress-container {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .step-item {
      cursor: pointer;
      .detail {
        cursor: pointer;
        display: flex;
        gap: 8px;
        .number {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          border: 1px solid rgba(0, 0, 0, 0.25);
          background: transparent;
          color: rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active-number {
          border: 1px solid #f9a601;
          background: #f9a601;
          color: white;
        }
        .title {
          color: rgba(0, 0, 0, 0.45);
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
        }
        .active-title {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .line {
      height: 2px;
      width: 100%;
      margin-top: 12px;
      background: transparent;
    }
    .active-line {
      background: #f9a601;
    }
    .icon-next {
      color: rgba(0, 0, 0, 0.45);
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
  .name-contianer {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      display: flex;
      height: 40px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      color: #4a4a4a;
      white-space: nowrap;
    }
    .input-name {
      width: 100%;
      max-width: 400px;
      min-width: 250px;
    }
    .error {
      border-color: red;
    }
    .reset {
      cursor: pointer;
      color: #2196f3;
      font-size: 16px;
      font-weight: 500;
      margin-left: 12px;
    }
  }
  .criteria-contianer {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 26px 500px;
    gap: 1px;
    background-color: #fafafa;
    .filter-column,
    .criteria-column,
    .summary-column {
      background: #fafafa;
      color: #969696;
      font-size: 12px;
      padding: 4px 16px;
    }

    .filter-select-container {
      overflow-y: auto;

      /* Style the scrollbar itself (width and background) */
      &::-webkit-scrollbar {
        width: 8px; /* Adjust scrollbar width */
        background-color: #f5f5f5; /* Scrollbar background */
      }

      /* Style the scrollbar thumb (the part you drag) */
      &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc; /* Thumb color */
        border-radius: 4px; /* Optional: roundness of the scrollbar thumb */
      }

      /* Style the scrollbar track (the part the thumb slides on) */
      &::-webkit-scrollbar-track {
        background-color: #e0e0e0; /* Track color */
      }

      /* Firefox scrollbar styles */
      scrollbar-width: thin; /* 'auto' or 'thin' */
      scrollbar-color: #bcbcbc #f5f5f5; /* thumb and track color */
      .item-container {
        cursor: pointer;
        background-color: #ffffff;
        color: #636363;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        border-bottom: 1px solid rgb(0 0 0 / 5%);

        .name {
          display: flex;
          align-items: center;
          gap: 4px;
          .noti {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #f9a601;
          }
        }
      }
      .select {
        background-color: #fff6e6;
      }
    }
    .criteria-select-container {
      overflow-y: auto;

      /* Style the scrollbar itself (width and background) */
      &::-webkit-scrollbar {
        width: 8px; /* Adjust scrollbar width */
        background-color: #f5f5f5; /* Scrollbar background */
      }

      /* Style the scrollbar thumb (the part you drag) */
      &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc; /* Thumb color */
        border-radius: 4px; /* Optional: roundness of the scrollbar thumb */
      }

      /* Style the scrollbar track (the part the thumb slides on) */
      &::-webkit-scrollbar-track {
        background-color: #e0e0e0; /* Track color */
      }

      /* Firefox scrollbar styles */
      scrollbar-width: thin; /* 'auto' or 'thin' */
      scrollbar-color: #bcbcbc #f5f5f5; /* thumb and track color */
      .check-group {
        width: 100%;
        .check-container {
          padding: 16px;
          display: flex;
          border-bottom: 1px solid rgb(0 0 0 / 5%);
        }
      }

      .date-select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        margin: auto 16px;
      }
    }
    .summary-select-container {
      overflow-y: auto;

      /* Style the scrollbar itself (width and background) */
      &::-webkit-scrollbar {
        width: 8px; /* Adjust scrollbar width */
        background-color: #f5f5f5; /* Scrollbar background */
      }

      /* Style the scrollbar thumb (the part you drag) */
      &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc; /* Thumb color */
        border-radius: 4px; /* Optional: roundness of the scrollbar thumb */
      }

      /* Style the scrollbar track (the part the thumb slides on) */
      &::-webkit-scrollbar-track {
        background-color: #e0e0e0; /* Track color */
      }

      /* Firefox scrollbar styles */
      scrollbar-width: thin; /* 'auto' or 'thin' */
      scrollbar-color: #bcbcbc #f5f5f5; /* thumb and track color */

      .group-container {
        text-align: left;
        color: #4a4a4a;
        .element {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 4px;

          span {
            color: #616161;
          }

          img {
            cursor: pointer;
            width: 15px;
            height: 15px;
          }
        }
        .line {
          height: 1px;
          margin: 8px auto;
          border: 1px solid#f5f5f5;
          background: #fafafa;
        }
      }
    }
  }
  .summary-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 526px;
    gap: 1px;
    background-color: #fafafa;
    .condition-list {
      background-color: red;
    }
    .summary {
      background-color: blue;
    }
    .summary,
    .condition-list {
      overflow-y: auto;

      /* Style the scrollbar itself (width and background) */
      &::-webkit-scrollbar {
        width: 8px; /* Adjust scrollbar width */
        background-color: #f5f5f5; /* Scrollbar background */
      }

      /* Style the scrollbar thumb (the part you drag) */
      &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc; /* Thumb color */
        border-radius: 4px; /* Optional: roundness of the scrollbar thumb */
      }

      /* Style the scrollbar track (the part the thumb slides on) */
      &::-webkit-scrollbar-track {
        background-color: #e0e0e0; /* Track color */
      }

      /* Firefox scrollbar styles */
      scrollbar-width: thin; /* 'auto' or 'thin' */
      scrollbar-color: #bcbcbc #f5f5f5; /* thumb and track color */
    }
  }
  .footer-container {
    padding: 16px;
    margin: auto -16px -16px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-top: 1px solid #f0f0f0;
    .ghost-btn {
      color: #ff9800;
      border-color: #ff9800;
      background-color: #ffffff;

      &:hover {
        color: #ff9800;
        border-color: #ff9800;
      }
    }
  }
}
</style>
