<template>
  <div class="container-fluid">
    <Header />
    <div class="content">
      <div class="setting">
        <div class="header">
          <img
            src="@/assets/images/icon_smart_target.svg"
            alt="smart target icon"
            class="icon"
          />
          <div class="title">
            <h4 class="main">{{ lbl['main-menu-smartassistant'] }}</h4>
            <div class="desc">{{ lbl['encourage-target'] }}</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="target-list">
          <div class="header">
            <div class="left">
              <strong>{{ lbl['select-target'] }}</strong>
              <a-badge
                :count="totalTarget"
                :number-style="{
                  backgroundColor: '#1890FF',
                  color: '#ffffff',
                }"
              />
            </div>
            <div class="right">
              <a-input-search
                :placeholder="lbl['search-target-placeholder']"
                size="large"
                class="input-search"
                @search="onSearch"
              />
              <a-button type="primary" ghost  class="ghost-btn">
                <img
                  :src="require('@/assets/images/UserFocus.svg')"
                  class="mr-2"
                  alt="UserFocus"
                />
                <strong>
                  {{ lbl['specific-target'] }}
                </strong>
              </a-button>
              <a-button type="primary" @click="addTemplate()">
                <img
                  :src="require('@/assets/images/Plus.svg')"
                  class="mr-2"
                  alt="plus"
                />
                <strong>
                  {{ lbl['add-template-target'] }}
                </strong>
              </a-button>
            </div>
          </div>
          <div v-if="isDisable" class="please-connect-line">
            {{ lbl['smarttarget-please-connect-line'] }}
          </div>
          <div class="target">
            <div
              class="box"
              v-for="item in displayedTargetBoxData"
              :key="item.header"
              :class="{ 'disable-box': isDisable }"
              @click="selectedTarget(item)"
            >
              <div :class="['target-icon']" :style="item.style">
                <img
                  width="26"
                  height="26"
                  :src="require(`@/assets/images/${item.icon}.png`)"
                  alt=""
                />
              </div>
              <div class="title">
                {{ item.heading[currentLocale] }}
              </div>
              <div class="sub-title">
                {{ item.description[currentLocale] }}
              </div>
            </div>
          </div>
          <div v-if="totalTarget > 8" class="see-more" @click="handleSeeMore()">
            {{
              isSeeMore
                ? lbl['user-permissions-see-less']
                : lbl['user-permissions-see-more']
            }}
            <img
              src="@/assets/images/Angle-down-yellow.svg"
              alt="see more"
              :class="{ rotate: isSeeMore }"
            />
          </div>
        </div>
      </div>
      <div class="list">
        <div class="header">
          <div class="title">{{ lbl['smart-target-list-text'] }}</div>
          <div class="sub-title">
            {{ lbl['smart-target-list-desc'] }}
          </div>
        </div>

        <div class="filterContainer">
          <div :class="['filterMain', showFilterMain ? 'active' : '']">
            <div class="filter">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item.text">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item.text, subFilter)"
                  >
                    <a-checkbox
                      :checked="filter[subFilter].value.includes(item.text)"
                    >
                      {{ item.text }}
                    </a-checkbox>
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="filteredDataList">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in filterItem"
            :key="'filter' + index"
          >
            {{ item
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>
        <div class="table-container">
          <a-skeleton v-if="loading.list" active />
          <a-table
            v-if="!loading.list"
            :columns="columns"
            :data-source="smartTargetListFilter"
            @change="onchangeTable"
            :pagination="{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
              pageSizeOptions: pageSizeOptions,
              pageSize: defaultPageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
            }"
            rowKey="id"
          >
            <template slot="action" slot-scope="text, record">
              <div
                class="
                  action-list
                  fs-16
                  text-left
                  d-flex
                  justify-content-center
                "
              >
                <a-tooltip v-if="record.status === 'draft'" placement="top">
                  <template slot="title">
                    <div class="mt-1">
                      {{ lbl['smart-target-edit'] }}
                    </div>
                  </template>
                  <router-link
                    :to="{
                      name: 'InactiveUserV2',
                      query: {
                        mode: 'edit',
                        id: record.id,
                        target: record.target,
                        sent: record.sent,
                        redeemed: record.redeemed,
                        used: record.used,
                      },
                    }"
                    class="mx-2"
                  >
                    <a-icon
                      @click="setItem(record.userListItem)"
                      :style="{ color: '#757575', fontSize: '18px ' }"
                      type="form"
                    />
                  </router-link>
                </a-tooltip>
                <a-tooltip v-if="record.status === 'draft'" placement="top">
                  <template slot="title">
                    <div class="mt-1">
                      {{ lbl['fgf-buttoncancel'] }}
                    </div>
                  </template>
                  <div class="mx-2">
                    <a-icon
                      :style="{ color: '#ff5252', fontSize: '18px ' }"
                      theme="filled"
                      type="delete"
                      @click="toggleDeleteModal(true, record.id)"
                    />
                  </div>
                </a-tooltip>
                <a-tooltip v-if="record.status !== 'draft'" placement="top">
                  <template slot="title">
                    <div class="mt-1">
                      {{ lbl['smart-target-view'] }}
                    </div>
                  </template>
                  <router-link
                    :to="{
                      name: 'InactiveUserV2',
                      query: {
                        mode: 'view',
                        id: record.id,
                        target: record.target,
                        sent: record.sent,
                        redeemed: record.redeemed,
                        used: record.used,
                      },
                    }"
                    class="mx-2"
                  >
                    <a-icon
                      @click="setItem(record.userListItem)"
                      :style="{ color: '#2196f3', fontSize: '18px ' }"
                      theme="filled"
                      type="eye"
                    />
                  </router-link>
                </a-tooltip>
                <a-tooltip v-if="record.status === 'created'" placement="top">
                  <template slot="title">
                    <div class="mt-1">
                      {{ lbl['fgf-buttoncancel'] }}
                    </div>
                  </template>
                  <div class="mx-2">
                    <a-icon
                      :style="{ color: '#ff5252', fontSize: '18px ' }"
                      theme="filled"
                      type="delete"
                      @click="toggleDeleteModal(true, record.id)"
                    />
                  </div>
                </a-tooltip>
              </div>
            </template>

            <template slot="status" slot-scope="text, record">
              <div class="statusContainer d-flex">
                <div :class="['statusIcon mt-1', record.statusLable]"></div>
                <div class="detail text-left">
                  <div class="date">
                    {{ record.date ? formatDateString(record.date) : '-' }}
                  </div>
                  <div class="status">{{ record.statusLable }}</div>
                </div>
              </div>
            </template>

            <template slot="reward" slot-scope="text, record">
              <div class="rewardContainer d-flex">
                <div v-if="record.imgPreview" class="rewardImg">
                  <img :src="record.imgPreview" class="w-100 h-100" alt="" />
                </div>
                <div class="rewardDetail text-left">
                  {{ record.reward }}
                </div>
              </div>
            </template>

            <template slot="userTarget" slot-scope="text, record">
              <div class="userTargetContainer d-flex">
                <div
                  :class="['userTargetItem']"
                  :style="record.userListItem[0].style"
                >
                  {{ record.userListItem[0].filtertext }}
                </div>
              </div>
            </template>
            <template slot="count" slot-scope="text, record">
              <div v-if="record.sendType == 'sms'">
                {{ lbl['smart-target-sms-count-warning'] }}
              </div>
              <div v-else>{{ text }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <DeleteModal
      :toggle="toggleDeleteModal"
      :isDeleteModal="isDeleteModal"
      :cfDelete="deleteCampiagn"
    />
    <AddTemplateModal
      :show="isCreateTemplate"
      @close="isCreateTemplate = false"
    />
    <TypeMessageModal
      :show="showTypeMessageModal"
      :isUnlimitSMS="isUnlimitSMS"
      :sms-limit="limitSmsCurrent"
      :line-limit="limitLineCurrent"
      :selected-target="selectedTargetItem"
      @clickNext="selectedTarget()"
      @closeModal="showTypeMessageModal = false"
    />

    <!-- Create Success -->
    <b-modal
      v-model="alertUserCreateSuccess"
      modal-class="modal-Confirm"
      :modalClass="[currentLocale]"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="modal-create-succress">
        <img
          :src="require(`@/assets/images/CheckCircle.svg`)"
          alt="check_circle"
        />
        <strong>
          {{ lbl['smarttarget-create-success-title'] }}
        </strong>
        <span>
          {{ getTotal(lbl['smarttarget-create-success-content']) }}
        </span>
        <button @click="onAccept()">
          {{ lbl['user-permissions-close'] }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSmarttarget from '@/core/Smarttarget/service/BzbsSmarttarget'
import AddTemplateModal from './modal/AddTemplateModal'
import DeleteModal from './modal/DeleteModal'
import TypeMessageModal from './modal/TypeMessageModal'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import moment from 'moment'
import Locale from '@/helper/locale.js'
export default {
  name: 'importUserSearch',
  components: {
    Header,
    DeleteModal,
    TypeMessageModal,
    AddTemplateModal,
  },
  mixins: [Mixin],
  data: function () {
    return {
      isSeeMore: false,
      isCreateTemplate: false,
      targetBoxData: [],
      targetBoxDataLess: [],
      totalTarget: 0,
      subFilter: 'status',
      showFilterMain: false,
      totalSmartTargetList: 0,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      filterValue: [],
      filter: {
        status: {
          value: [],
          input: [
            {
              text: 'All Status',
              value: 'All Status',
            },
            {
              text: 'Draft',
              value: 'draft',
            },
            {
              text: 'Publish',
              value: 'publish',
            },
            {
              text: 'Cancelled',
              value: 'cancelled',
            },
            {
              text: 'Created',
              value: 'created',
            },
          ],
          label: 'Status',
          type: 'checkbox',
        },
        target: {
          value: [],
          input: [
            {
              text: 'All Target',
              value: 'All Target',
            },
          ],
          label: 'Target',
          type: 'checkbox',
        },
      },
      columns: [
        {
          title: 'Send date & Status',
          dataIndex: 'status',
          key: 'status',
          width: 220,
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: 'Reward',
          dataIndex: 'reward',
          key: 'reward',
          width: 280,
          scopedSlots: {
            customRender: 'reward',
          },
        },
        {
          title: 'User Target',
          dataIndex: 'userTarget',
          key: 'userTarget',
          scopedSlots: {
            customRender: 'userTarget',
          },
        },
        {
          title: 'Target',
          dataIndex: 'target',
          key: 'target',
          align: 'right',
        },
        {
          title: 'Sent',
          dataIndex: 'sent',
          key: 'sent',
          align: 'right',
        },
        {
          title: 'Unique Click',
          dataIndex: 'click',
          key: 'click',
          align: 'right',
          scopedSlots: {
            customRender: 'count',
          },
        },
        {
          title: 'Redeemed',
          dataIndex: 'redeemed',
          key: 'redeemed',
          align: 'right',
          scopedSlots: {
            customRender: 'count',
          },
        },
        {
          title: 'Used',
          dataIndex: 'used',
          key: 'used',
          align: 'right',
          scopedSlots: {
            customRender: 'count',
          },
        },
        // {
        //   title: 'Cost',
        //   dataIndex: 'cost',
        //   key: 'cost',
        // },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      smartTargetList: [],
      smartTargetListFilter: [],
      isDeleteModal: false,
      loading: {
        list: false,
      },
      selectedDeleteId: '',
      currentPage: 1,
      currentLocale: 'th',
      filterItem: [],
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
      showTypeMessageModal: false,
      selectedTargetItem: null,
      isUnlimitSMS: false,
      limitSmsCurrent: 0,
      limitLineCurrent: 0,
      alertUserCreateSuccess: false,
      alertTotal: 0,
      itemsToShow: 8,
    }
  },
  computed: {
    displayedTargetBoxData() {
      if (this.isSeeMore) {
        return this.targetBoxData
      } else {
        return this.targetBoxData.slice(0, this.itemsToShow)
      }
    },
    allFilter() {
      return [...this.filter.status.value, ...this.filter.target.value]
    },
    isDisable() {
      if (this.loginType == 'Line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
    dataDetailPerPage() {
      let lenght = this.smartTargetListFilter.length
      let lastItemPage = lenght % 10
      let lastPage = Math.ceil(lenght / 10)
      if (this.currentPage === lastPage) {
        return lastItemPage
      }
      return lenght < 10 ? lenght : 10
    },
  },
  created() {
    Account.bzbsAnalyticTracking(
      'smart_target_page',
      'smart_target',
      'view_smart_target',
      'on view',
    )
    if (this.$route.query.create === 'success') {
      this.alertUserCreateSuccess = true
      this.alertTotal = this.$route.query.total ? this.$route.query.total : '0'
    }
    this.init()
  },
  methods: {
    addTemplate() {
      this.isCreateTemplate = true
    },
    onSearch(value) {
      console.log(value)
    },
    handleSeeMore() {
      this.isSeeMore = !this.isSeeMore
    },
    init() {
      this.handleFooter(true)
      this.getSmarttargetBlob().then(() => {
        this.getSmarttargetList()
        this.getCRMPlusProfile().then(result => {
          this.loginType = result.LoginType
          this.appId = result.AppId
          this.liffId = result.LiffId
          this.liffLanding = result.LiffLanding
          this.isUnlimitSMS = !result.LimitSms
          this.limitSmsCurrent = result.LimitSmsCurrent
            ? parseInt(result.LimitSmsCurrent)
            : 0
          this.limitLineCurrent = result.LineChannelTargetLimitValue
            ? parseInt(result.LineChannelTargetLimitValue)
            : 0
        })
        this.currentLocale = Locale.getLocaleShort()
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    onchangeTable(e) {
      this.currentPage = e.current
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    setItem(data) {
      console.log(data)
      sessionStorage.setItem('target', JSON.stringify(data[0]))
    },
    selectFilter(item, type) {
      console.log(item)
      if (this.filter[type].value.includes(item)) {
        this.filter[type].value = this.filter[type].value.filter(
          fItem => fItem !== item,
        )
      } else {
        if (item === 'All Status' || item === 'All Target') {
          this.filter[type].value = []
        } else {
          let deleteType = 'All Target'
          if (type === 'status') deleteType = 'All Status'
          if (this.filter[type].value.indexOf(deleteType) > -1) {
            this.filter[type].value.splice(
              this.filter[type].value.indexOf(deleteType),
              1,
            )
          }
        }
        this.filter[type].value.push(item)
      }
    },
    removeFilter(item) {
      let type = 'target'
      if (this.filter.status.value.includes(item)) type = 'status'
      if (item !== 'All Status' || item !== 'All Target') {
        this.filter[type].value = this.filter[type].value.filter(
          fItem => fItem !== item,
        )
      }
      // if (this.filter[type].value.length === 0) {
      //   if (type === 'status') {
      //     this.filter[type].value.push('All Status')
      //   } else {
      //     this.filter[type].value.push('All Target')
      //   }
      // }
      this.filteredDataList()
    },
    resetFilter() {
      this.filter.status.value = []
      this.filter.target.value = []
      this.filteredDataList()
    },
    filteredDataList() {
      this.filterItem = [...this.allFilter]
      let filterlength = [...this.allFilter].length
      let filterr = []
      Account.bzbsAnalyticTracking(
        'smart_target_page',
        'smart_target',
        'click_smart_filter',
        'on click',
      )
      if (
        (this.allFilter.includes('All Target') &&
          this.allFilter.includes('All Status')) ||
        filterlength === 0
      ) {
        console.log(1)
        filterr = this.smartTargetList
      } else if (
        this.allFilter.includes('All Target') &&
        !this.allFilter.includes('All Status')
      ) {
        if (filterlength === 1) {
          filterr = this.smartTargetList
        } else {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.statusLable)
          })
        }
      } else if (
        !this.allFilter.includes('All Target') &&
        this.allFilter.includes('All Status')
      ) {
        if (filterlength === 1) {
          filterr = this.smartTargetList
        } else {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.userListItem[0].filtertext)
          })
        }
      } else {
        if ([...this.filter.target.value].length === 0) {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.statusLable)
          })
        } else if ([...this.filter.status.value].length === 0) {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.userListItem[0].filtertext)
          })
        } else {
          filterr = this.smartTargetList.filter(f => {
            return (
              this.allFilter.includes(f.userListItem[0].filtertext) &&
              this.allFilter.includes(f.statusLable)
            )
          })
        }
      }

      this.smartTargetListFilter = filterr
      console.log('filterr', filterr)
      console.log('this.smartTargetList', this.smartTargetListFilter)
      this.totalSmartTargetList = this.smartTargetListFilter.length
    },
    formatDateString(dateString, format = 'DD/MM/YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    toggleDeleteModal(type, id) {
      this.isDeleteModal = type
      this.selectedDeleteId = id
    },
    handleSMS(record) {
      console.log('========================')
      console.log(record.SendType)
      console.log('========================')
    },
    selectedTarget(data) {
      if (!this.isDisable) {
        Account.bzbsAnalyticTracking(
          'smart_target_page',
          'smart_target',
          'click_smart_target_create_' + data.name,
          'on click',
        )
        this.selectedTargetItem = data
        //TODO Show POPUP
        this.showTypeMessageModal = true
        console.log(this.showTypeMessageModal)
        // this.$store.dispatch('setSelectedSmartTarget', data)
        // sessionStorage.setItem('target', JSON.stringify(data))
        // this.$router.push({
        //   name: 'InactiveUserV2',
        //   query: { mode: 'create', id: data.name },
        // })
      }
    },
    getSmarttargetBlob() {
      return new Promise((resolve, reject) => {
        BzbsSmarttarget.getSmarttargetBlob()
          .then(res => {
            console.log('blob', res)
            Account.bzbsAnalyticTracking(
              'smart_target_page',
              'smart_target',
              'view_smart_select_target',
              'on view',
            )
            this.targetBoxData = res.data
            this.totalTarget = res.data ? parseInt(res.data.length) : 0
            let list = res.data.map(t => {
              return t.filtertext
            })
            let newlist = [...new Set(list)]
            newlist.forEach(item => {
              this.filter.target.input.push({
                text: item,
                value: item,
              })
            })
            resolve(res)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    getSmarttargetList() {
      this.loading.list = true
      BzbsSmarttarget.getSmarttargetList(
        [...this.filter.status.value],
        [...this.filter.target.value],
      )
        .then(res => {
          console.log('getSmarttargetList', res)
          Account.bzbsAnalyticTracking(
            'smart_target_page',
            'smart_target',
            'view_smart_list',
            'on view',
          )
          this.smartTargetList = res.data.data
            .sort((a, b) => b.Id - a.Id)
            .map(item => {
              let target = ''
              let targetLable = ''
              let userListItem = {}
              userListItem = this.targetBoxData.filter(i => {
                return item.UserTarger == i.name
              })
              let imgPreview = ''
              let jsonData = {}
              if (item.LineTemplate) {
                if (item.LineTemplate.LineSSTemplateActions.length == 2) {
                  jsonData = JSON.parse(
                    item.LineTemplate.LineSSTemplateActions[1].Json,
                  )
                }
              }
              if (jsonData.baseUrl) {
                imgPreview = jsonData.baseUrl + '/240'
              }
              let obj = {
                id: item.Id,
                status: item.Status,
                statusLable:
                  item.Status[0].toUpperCase() + item.Status.substring(1),
                date: new Date(item.SendDate * 1000),
                reward: item.Name,
                userTarget: target,
                targetLable,
                target:
                  item.Status == 'draft'
                    ? item.TargetCount
                    : item.MarketingCampaignResult.TotalSent,
                sent:
                  parseInt(item.MarketingCampaignResult.CountSent) >= 0
                    ? item.MarketingCampaignResult.CountSent
                    : '-',
                imgPreview,
                click: item.MarketingCampaignResult.CountClick,
                redeemed: item.MarketingCampaignResult.CountRedeem,
                used: item.MarketingCampaignResult.CountUsed,
                cost: '-',
                sendType: item.SendType,
                userListItem,
              }
              let sendDateParse = Date.parse(
                moment(new Date(item.SendDate * 1000)).add(-7, 'hours'),
              )
              if (
                sendDateParse > Date.parse(new Date()) &&
                obj.status !== 'cancelled'
              ) {
                obj.status = 'created'
                obj.statusLable = 'Created'
              }
              return obj
            })
          this.smartTargetListFilter = this.smartTargetList
          console.log(this.smartTargetList)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading.list = false
        })
    },
    deleteCampiagn() {
      this.handleLoading(true)
      BzbsSmarttarget.deleteCampiagn(this.selectedDeleteId)
        .then(res => {
          console.log(res)
          this.toggleDeleteModal(false, '')
          this.getSmarttargetList()
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    onAccept() {
      this.$router.push({
        query: {},
      })
      this.alertUserCreateSuccess = false
    },
    getTotal(text) {
      try {
        return text.replace('256', this.getcomma(this.alertTotal))
      } catch {
        return 'We will work in background to send your message in 15 Minute after created.'
      }
    },
    getcomma(string) {
      var number = 0
      try {
        number = parseInt(string)
      } catch {
        number = string
      }

      try {
        if (number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
          return 0
        }
      } catch {
        return number
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 90%;
  margin: 16px auto auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .list,
  .setting {
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #f5f5f5;
    border-radius: 12px;
    padding: 20px 30px;
  }

  .setting {
    .header {
      display: flex;
      gap: 16px;
      align-items: center;

      .icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
      }
      .title {
        .main {
          color: #212121;
          font-size: 34px;
          margin: 0px;
        }
        .desc {
          color: #757575;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .line {
      background-color: #f5f5f5;
      height: 1px;
      margin: 12px -30px auto;
    }
    .target-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px 0px;
      .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          gap: 4px;
          strong {
            color: #424242;
            font-size: 20px;
            letter-spacing: 0.15px;
          }
        }
        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          .input-search {
            min-width: 250px;
            max-width: 300px;
          }

          @media screen and (max-width: 787px) {
            flex-wrap: wrap;
          }
        }
      }
      .please-connect-line {
        padding: 0 30px 16px;
        color: red;
      }
      .target {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        .box {
          display: flex;
          width: 320px;
          background: #fafafa;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .target-icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 40px;
            &.orange {
              background-color: #fff3e0;
            }
            &.pink {
              background-color: #fdecf5;
            }
            &.pur {
              background-color: #ede7f6;
            }
            &.blue {
              background-color: #d6e9fa;
            }
          }
          .title {
            color: #424242;
            font-size: 16px;
            font-weight: 600;
          }
          .sub-title {
            color: #757575;
            font-size: 12px;
            letter-spacing: 0.4px;
          }
        }
        .disable-box {
          opacity: 0.5;
          &:hover {
            box-shadow: unset;
          }
        }
      }
      .see-more {
        cursor: pointer;
        color: #f9a601;
        font-size: 16px;
        font-weight: 700;

        .rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .list {
    .header {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .title {
        color: #424242;
        font-size: 20px;
        font-weight: 500;
      }
      .sub-title {
        color: #b5b5c3;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .filterContainer {
      margin: -6px -30px !important;
    }
    .statusContainer {
      margin: 10px 0 18px 0;
      gap: 26px;
      padding: 0 0 0 12px;
      .statusIcon {
        width: 16px;
        height: 16px;
        border-radius: 29px;
        &.Created,
        &.Pending,
        &.Draft {
          background-color: #ffc107;
        }
        &.Sent,
        &.Complete,
        &.Publish {
          background-color: #4caf50;
        }
        &.Inactive,
        &.Cancelled {
          background-color: #b5b5c3;
        }
      }
      .detail {
        .date {
          font-size: 14px;
          font-weight: 600;
          color: #3699ff;
        }
        .status {
          font-size: 13px;
          font-weight: 500;
          color: #b5b5c3;
        }
      }
    }
    .userTargetContainer {
      width: 100%;
      min-height: 50px;
      text-align: left;
      .userTargetItem {
        height: fit-content;
        padding: 4px 9px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        &.inactive {
          color: #ffa800;
          background-color: #fff4de;
        }
        &.topspender {
          color: #ef5da8;
          background-color: #fdecf5;
        }
        &.neverpurchase {
          color: #673ab7;
          background-color: #ede7f6;
        }
        &.birthday {
          color: #1e88e5;
          background-color: #d6e9fa;
        }
      }
    }
  }
}
.ghost-btn {
  color: #ff9800;
  border-color: #ff9800;
  background-color: #ffffff;

  &:hover {
    color: #ff9800;
    border-color: #ff9800;
  }
}
</style>
<style>
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  font-size: 14px;
}
</style>
